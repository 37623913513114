import {useState} from "react";
import {ApiConsumer} from "../api/Api";
import {APIRoutes} from "../routes/api";

export default function ContactForm() {
    const [details, setDetails] = useState({})
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)

    function send() {
        setLoading(true)
        ApiConsumer.post(APIRoutes.API.EMAIL, details)
            .then(() => {
                setSent(true)
            })
            .catch(err => {
                setSent(false)
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    return <>
        <div className='card shadow-none border-darker border-2 pb-2' style={{color: '#636e72'}}>
            <div className='container mt-3'>
                <form onSubmit={event => {
                    event.preventDefault()
                    send()
                }}>
                    {
                        sent ?
                            <h5 className='tx-success mt-2 mb-5'><strong>Submitted Successfully.</strong> Thank You. Our
                                friendly staff will review your Enquiry/Request
                                and get back to you.</h5> :
                            <>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="row g-3 mb-3">
                                            <div className="col">
                                                <label htmlFor="exampleFormControlInput1"
                                                       className="form-label fw-bold">first
                                                    name</label>
                                                <input type="text"
                                                       className="form-control"
                                                       placeholder="first name"
                                                       aria-label="first name"
                                                       onChange={event => setDetails(prevState => ({
                                                           ...prevState,
                                                           ['first_name']: event.target.value
                                                       }))}
                                                       value={details?.first_name}
                                                />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="exampleFormControlInput1"
                                                       className="form-label fw-bold">last
                                                    name</label>
                                                <input type="text"
                                                       className="form-control"
                                                       placeholder="last name"
                                                       aria-label="last name"
                                                       onChange={event => setDetails(prevState => ({
                                                           ...prevState,
                                                           ['last_name']: event.target.value
                                                       }))}
                                                       value={details?.last_name}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">email
                                                address</label>
                                            <input type="email"
                                                   className="form-control"
                                                   id="exampleFormControlInput1"
                                                   placeholder="info@nsztema.co.za"
                                                   onChange={event => setDetails(prevState => ({
                                                       ...prevState,
                                                       ['email']: event.target.value
                                                   }))}
                                                   value={details?.email}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">phone
                                                number</label>
                                            <input type="text"
                                                   className="form-control"
                                                   id="exampleFormControlInput1"
                                                   placeholder="0114829327"
                                                   onChange={event => setDetails(prevState => ({
                                                       ...prevState,
                                                       ['phone']: event.target.value
                                                   }))}
                                                   value={details?.phone}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1"
                                                   className="form-label fw-bold">message</label>
                                            <textarea className="form-control"
                                                      id="exampleFormControlTextarea1"
                                                      rows="2"
                                                      onChange={event => setDetails(prevState => ({
                                                          ...prevState,
                                                          ['message']: event.target.value
                                                      }))}
                                                      value={details?.message}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="mb-3 float-end">
                                            <button className='btn btn-success' type='submit' disabled={loading}>
                                                {loading ?
                                                    <><span className="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true">  </span>{' '} </>
                                                    : null}
                                                send message
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </form>
            </div>

        </div>
    </>
}
